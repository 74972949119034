<template>
  <div>
    请输入电话号:
    <el-input style="width: 150px;margin-right:20px;" v-model="usertel"></el-input>
    <el-button type="primary" @click="register">注册</el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      usertel: "",
    };
  },
  methods: {
    register() {
      this.axios
        .post("/gu/gu_cust_login_by_tel", this.qs.stringify({ tel: this.usertel,rf:'后台手动添加' }))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
